import { useContext } from 'react'
import { motion } from 'framer-motion'

import Auth from '../context/Auth'

const Score = () => {
  const auth = useContext(Auth)

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2 }}>
      <div className="flex justify-between mb-8 pb-2 border-b border-gray-800">
        <h1 className="text-2xl font-semibold">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="inline w-6 mr-3 -mt-1"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" /></svg>
          Pontgyűjtés
        </h1>
        <div className="mt-1"><span className="font-semibold">{auth.user.score}</span> pont</div>
      </div>
      <p>Örömünkre szolgál, ha minél több honfitársunk használja az NKKT weboldalakat. Ezért díjazzuk a felhasználói aktivitást és a releváns tartalmak létrehozását. Az alábbi tevékenységekért kaphatóak pontok:</p>
      <ul className="mt-1">
        <li><span className="inline-block w-32 font-semibold">100 pont</span><span>Regisztrációért járó egyszeri bónusz</span></li>
        <li><span className="inline-block w-32 font-semibold">50 pont</span><span>Teljesen hibátlan kvíz esetén plusz ennyi pont</span></li>
        <li><span className="inline-block w-32 font-semibold">30 pont</span><span>Minden 200 karakter feletti hozzászólásért</span></li>
        <li><span className="inline-block w-32 font-semibold">10 pont</span><span>Minden 100 és 200 karakter közötti hozzászólásért</span></li>
        <li><span className="inline-block w-32 font-semibold">10 pont</span><span>Minden helyes kvíz kérdés megválaszolásáért</span></li>
        <li><span className="inline-block w-32 font-semibold">5 pont</span><span>Minden 100 karakter alatti hozzászólásért</span></li>
        <li><span className="inline-block w-32 font-semibold">1 pont</span><span>Minden bejelentkezéskor</span></li>
      </ul>
      <h2 className="mt-6 mb-1 text-lg font-semibold">Pontok beváltása</h2>
      <p>Célunk, hogy mindenki megtalálja a hozzá közel illő dolgot, így számos dologra be lehet cserélni az összegyűjtött virtuális pontokat:</p>
      <ul className="mt-1">
        <li><span className="inline-block w-32 font-semibold">3.000 pont</span><span>Mézeskalács csomag</span></li>
        <li><span className="inline-block w-32 font-semibold">1.500 pont</span><span>Kerámia nyaklánc</span></li>
        <li><span className="inline-block w-32 font-semibold">500 pont</span><span>Bőr kitűző</span></li>
      </ul>
    </motion.div>
  )
}

export default Score