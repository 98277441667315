import { motion } from 'framer-motion'

import { styleButton } from '../styles/classes'

import LogoSzakkor from '../images/logo-aszakkor.png'
import LogoNaputon from '../images/logo-naputon.png'
import NewsSzakkor from '../images/gyongyfuzes-16.jpg'
import NewsNaputon from '../images/hir-100.jpg'

const Home = () => {
  return (
  <>
    <motion.div
      className="flex justify-between mb-8 pb-2 border-b border-gray-800"
      initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2 }}
    >
      <h1 className="text-2xl font-semibold">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="inline w-6 mr-3 -mt-1"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" /></svg>
        Hírfolyam
      </h1>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 mt-2"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
    </motion.div>

    <motion.div
      className="bg-white rounded-lg shadow mb-6"
      initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.4 }}
    >
      <div className="flex">
        <div>
          <div className="flex justify-between border-b-2 border-gray-200 px-5 py-2">
            <h2 className="font-semibold">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="inline w-6 mr-4 -mt-1 text-gray-600"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" /></svg>
              Kalotaszegi hajcsat
            </h2>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="inline w-6 text-gray-600"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" /></svg>
          </div>
          <div className="lg:flex px-5 py-4">
            <div className="flex-none w-80 rounded overflow-hidden">
            <a href="https://aszakkor.hu/szakkorok/gyongyfuzes/6"><motion.img
                src={NewsSzakkor} alt="Kalotaszegi hajcsat"
                whileHover={{ scale: 1.04 }} transition={{ duration: 0.25 }}
              /></a>
            </div>
            <div className="pl-6">
              <p className="text-justify mb-6">A párta díszítményéből készült hajcsat készítése, gyöngyhímzés technikája, bélelés módja.</p>
              <p className="pb-3"><a href="https://aszakkor.hu/szakkorok/gyongyfuzes/6" target="_blank" rel="noreferrer" className={styleButton}>Megtekintés az oldalon</a><span className="ml-6">Nehézség:</span> <span className="font-semibold text-green">közepes</span></p>
            </div>
          </div>
        </div>
        <div className="hidden xl:block flex-none w-44 text-center border-l-2 border-gray-200">
          <div className="border-b-2 border-gray-200 px-4 py-3">
            <p className="font-light">2021.02.28.</p>
            <p className="text-base">5 nappal ezelőtt</p>
          </div>
          <div className="p-4">
            <div className="mb-3">A Szakkör</div>
            <a href="https://aszakkor.hu" target="_blank" rel="noreferrer"><motion.img
              src={LogoSzakkor} className="w-24 mx-auto" alt="A Szakkör"
              whileHover={{ scale: 1.05 }} transition={{ duration: 0.25 }}
            /></a>
          </div>
        </div>
      </div>
    </motion.div>

    <motion.div
      className="bg-white rounded-lg shadow mb-6"
      initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5 }}
    >
      <div className="flex">
        <div>
          <div className="flex justify-between border-b-2 border-gray-200 px-5 py-2">
            <h2 className="font-semibold">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="inline w-6 mr-4 -mt-1 text-gray-600"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" /></svg>
              100 év, 100 fotó, 100 élet
            </h2>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="inline w-6 text-gray-600"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z" /></svg>
          </div>
          <div className="lg:flex px-5 py-4">
            <div className="flex-none w-80 rounded overflow-hidden">
              <a href="https://naputon.hu/hirek/100-ev-100-foto-100-elet-15"><motion.img
                src={NewsNaputon} alt="100 év, 100 fotó, 100 élet"
                whileHover={{ scale: 1.04 }} transition={{ duration: 0.25 }}
              /></a>
            </div>
            <div className="pl-6">
              <p className="text-justify mb-6">Minden család kincsei között megkülönböztetett tisztelet övezi a régi, olykor elsárgult fotókat, amelyen a felmenők, rokonok életének egy fontos állomásába pillanthatunk be. A megkopott fotókon megelevenedik a múlt, a fotón megörökített pillanat mögött olykor a családi legendárium fontos eseményei húzódnak meg.</p>
              <p className="pb-3"><a href="https://naputon.hu/hirek/100-ev-100-foto-100-elet-15" target="_blank" rel="noreferrer" className={styleButton}>Megtekintés az oldalon</a><span className="ml-6">Régió:</span> <span className="font-semibold text-green">országos</span></p>
            </div>
          </div>
        </div>
        <div className="hidden xl:block flex-none w-44 text-center border-l-2 border-gray-200">
          <div className="border-b-2 border-gray-200 px-4 py-3">
            <p className="font-light">2021.01.15.</p>
            <p className="text-base">25 nappal ezelőtt</p>
          </div>
          <div className="p-4">
            <div className="mb-3">Naputon</div>
            <a href="https://naputon.hu" target="_blank" rel="noreferrer"><motion.img
              src={LogoNaputon} className="w-24 mx-auto" alt="Naputon"
              whileHover={{ scale: 1.05 }} transition={{ duration: 0.25 }}
            /></a>
          </div>
        </div>
      </div>
    </motion.div>
  </>
  )
}

export default Home