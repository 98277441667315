import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

import { styleButton } from '../styles/classes'

import LogoSzakkor from '../images/logo-aszakkor.png'
import LogoGasztrojarat from '../images/logo-gasztrojarat.png'
import LogoNaputon from '../images/logo-naputon.png'
import LogoJogyakorlatOn from '../images/logo-jogyakorlaton.png'
import LogoNMI from '../images/logo-nmi.png'
import Parlament from '../images/parlament.jpg'

const Frontend = ({ children }) => {
  return (
    <main className="md:flex">
      <section className="hidden md:block md:w-4/12 xl:w-96 bg-white shadow-md 2xl:px-8 2xl:py-2 divide-y-2 divide-gray-200">
        <motion.div className="py-4" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2 }}>
          <div className="flex">
            <a href="https://aszakkor.hu" target="_blank" rel="noreferrer"><motion.img
              src={LogoSzakkor} className="w-20 ml-6" alt="Naputon"
              whileHover={{ scale: 1.05 }} transition={{ duration: 0.25 }}
            /></a>
            <div className="mt-1 ml-6">
              <p className="text-xl lg:text-2xl font-light">A Szakkör</p>
              <a href="https://aszakkor.hu" target="_blank" rel="noreferrer" className="italic text-green hover:text-gray-800">aszakkor.hu</a>
            </div>
          </div>
        </motion.div>
        <motion.div className="py-4" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.3 }}>
          <div className="flex">
            <a href="https://gasztrojarat.hu" target="_blank" rel="noreferrer"><motion.img
              src={LogoGasztrojarat} className="w-20 ml-6" alt="Gasztrojárat"
              whileHover={{ scale: 1.05 }} transition={{ duration: 0.25 }}
            /></a>
            <div className="mt-1 ml-6">
              <p className="text-xl lg:text-2xl font-light">Gasztrojárat</p>
              <a href="https://gasztrojarat.hu" target="_blank" rel="noreferrer" className="italic text-green hover:text-gray-800">gasztrojarat.hu</a>
            </div>
          </div>
        </motion.div>
        <motion.div className="py-4" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.4 }}>
          <div className="flex">
            <a href="https://naputon.hu" target="_blank" rel="noreferrer"><motion.img
              src={LogoNaputon} className="w-20 ml-6" alt="Naputon"
              whileHover={{ scale: 1.05 }} transition={{ duration: 0.25 }}
            /></a>
            <div className="mt-1 ml-6">
              <p className="text-xl lg:text-2xl font-light">Naputon</p>
              <a href="https://naputon.hu" target="_blank" rel="noreferrer" className="italic text-green hover:text-gray-800">naputon.hu</a>
            </div>
          </div>
        </motion.div>
        <motion.div className="py-4" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5 }}>
          <div className="flex">
            <a href="https://jogyakorlaton.hu" target="_blank" rel="noreferrer"><motion.img
              src={LogoJogyakorlatOn} className="w-20 ml-6" alt="Jó gyakorlat ON"
              whileHover={{ scale: 1.05 }} transition={{ duration: 0.25 }}
            /></a>
            <div className="mt-1 ml-6">
              <p className="text-xl lg:text-2xl font-light">Jó gyakorlat ON</p>
              <a href="https://jogyakorlaton.hu" target="_blank" rel="noreferrer" className="italic text-green hover:text-gray-800">jogyakorlaton.hu</a>
            </div>
          </div>
        </motion.div>
        <motion.div className="py-4" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.6 }}>
          <div className="flex">
            <a href="https://nmi.hu" target="_blank" rel="noreferrer"><motion.img
              src={LogoNMI} className="w-20 ml-6" alt="Nemzeti Művelődési Intézet"
              whileHover={{ scale: 1.05 }} transition={{ duration: 0.25 }}
            /></a>
            <div className="mt-1 ml-6">
              <p className="text-xl lg:text-2xl font-light">NMI</p>
              <a href="https://nmi.hu" target="_blank" rel="noreferrer" className="italic text-green hover:text-gray-800">nmi.hu</a>
            </div>
          </div>
        </motion.div>
      </section>
      <section className="md:w-8/12 lg:w-10/12 mx-auto 2xl:max-w-screen-lg lg:pt-10">
        <div>
          <div className="lg:flex lg:items-center text-gray-dark">
            <motion.div className="lg:w-1/2 xl:w-4/6 mx-8 my-6 xl:m-0 lg:pr-2 xl:pr-16 xl:pl-8 2xl:pl-0" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <h1 className="font-medium text-3xl">Nemzeti Kultúrális Közösségi Tér</h1>
              <p className="mt-4">A közösségi profil lehetővé teszi a felhasználóknak, hogy testre szabhassák a saját profiljuk publikus és közösségi megjelenését, illetve egyszerűen és kényelmesen - további regisztráció nélkül - elérjék a portál-csoporthoz tartozó összes weboldal minden bejelentkezéshez kötött funkcióját</p>
              <p className="mt-6"><Link to="/regisztracio" className={styleButton}>Profil regisztrálása</Link></p>
            </motion.div>
            <div className="lg:w-1/2 xl:w-2/6 bg-white rounded shadow p-8 pt-4">
              {children}
            </div>
          </div>
          <motion.div className="mb-10 lg:my-20" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.4 }}>
            <img src={Parlament} alt="Parlament" className="2xl:rounded-lg shadow-xl" />
          </motion.div>
        </div>
      </section>
    </main>
  )
}

export default Frontend