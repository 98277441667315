export const county = [
  { value: 2, label: 'Baranya' },
  { value: 3, label: 'Bács-Kiskun' },
  { value: 4, label: 'Békés' },
  { value: 5, label: 'Borsod-Abaúj-Zemplén' },
  { value: 6, label: 'Csongrád-Csanád' },
  { value: 7, label: 'Fejér' },
  { value: 8, label: 'Győr-Moson-Sopron' },
  { value: 9, label: 'Hajdú-Bihar' },
  { value: 10, label: 'Heves' },
  { value: 11, label: 'Jász-Nagykun-Szolnok' },
  { value: 12, label: 'Komárom-Esztergom' },
  { value: 13, label: 'Nógrád' },
  { value: 14, label: 'Pest' },
  { value: 15, label: 'Somogy' },
  { value: 16, label: 'Szabolcs-Szatmár-Bereg' },
  { value: 17, label: 'Tolna' },
  { value: 18, label: 'Vas' },
  { value: 19, label: 'Veszprém' },
  { value: 20, label: 'Zala' }
]