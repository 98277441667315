import { useState } from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import toast, { Toaster } from 'react-hot-toast'

import { styleInput, styleButton } from '../styles/classes'

const Registration = () => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    passwd: ''
  })

  const formChange = event => {
    const fields = { ...form }
    fields[event.target.name] = event.target.value.trim()
    setForm(fields)
  }

  const formSubmit = () => {
    toast('Fejlesztés alatt...', { icon: '🛠️' })
  }

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.3 }}>
      <div className="mb-6">
        <label htmlFor="name" className="leading-8 text-base">Név</label>
        <input type="text" name="name" id="name" onChange={formChange} className={styleInput} autoFocus />
      </div>
      <div className="mb-4">
        <label htmlFor="email" className="leading-8 text-base">Email</label>
        <input type="email" name="email" id="email" onChange={formChange} className={styleInput} />
      </div>
      <div className="mb-6">
        <label htmlFor="passwd" className="leading-8 text-base">Jelszó</label>
        <input type="password" name="passwd" id="passwd" onChange={formChange} className={styleInput} />
      </div>
      <div className="flex justify-between">
      <button className={styleButton} onClick={formSubmit}>Regisztráció</button>
        <Link to="/" className="text-green hover:text-gray-800 mt-2">vissza</Link>
      </div>
      <Toaster />
      <Toaster />
    </motion.div>
  )
}

export default Registration