import { useContext, useState } from 'react'
import { motion } from 'framer-motion'
import Select from 'react-select'
import toast, { Toaster } from 'react-hot-toast'

import Auth from '../context/Auth'
import { styleInput, styleButton } from '../styles/classes'

import ProfilePic from '../images/profile.jpg'

import { country } from '../consts/country'
import { county } from '../consts/county'

const Profile = () => {
  const auth = useContext(Auth)

  const [form, setForm] = useState({
    name: auth.user.name,
    email: auth.user.email,
    country: auth.user.country,
    county: auth.user.county,
    passwd: ''
  })

  const formChange = event => {
    const fields = { ...form }
    let value = event.target.value

    if (typeof value === 'string') {
      value = value.trim()
    }

    fields[event.target.name] = value
    setForm(fields)
  }

  const submitForm = event => {
    event.preventDefault()
    const user = { ...auth.user }

    user.name = form.name
    user.email = form.email
    user.county = form.county

    auth.setUser(user)
    toast.success('Sikeres mentés!', {
      iconTheme: { primary: '#007473' }
    })
  }

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2 }}>
      <h1 className="text-2xl font-semibold text-left mb-8 pb-2 border-b border-gray-800">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="inline w-6 mr-3 -mt-1"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
        Profilom
      </h1>
      <div className="lg:flex">
        <div className="lg:w-1/2 bg-white px-9 pt-5 pb-8 shadow">
          <div className="mb-4">
            <label htmlFor="name" className="leading-8 text-base">Név</label>
            <input type="text" name="name" id="name" defaultValue={form.name} onChange={formChange} className={styleInput} />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="leading-8 text-base">Email</label>
            <input type="email" name="email" id="email" defaultValue={form.email} onChange={formChange} className={styleInput} />
          </div>
          <div className="mb-4">
            <label className="leading-8 text-base">Ország</label>
            <Select
              defaultValue={{ ...country.find(item => item.value === form.country) }} options={country}
              onChange={val => {formChange({ target: { name:'country', value: val.value }} )}}
            />
          </div>
          {form.country === 1 ?
          <div className="mb-4">
            <label className="leading-8 text-base">Megye</label>
            <Select
              defaultValue={{ ...county.find(item => item.value === form.county) }} options={county}
              onChange={val => {formChange({ target: { name:'county', value: val.value }} )}}
            />
          </div>
          : null }
          <div className="mb-6">
            <label htmlFor="passwd" className="leading-8 text-base">Új jelszó</label>
            <input type="password" name="passwd" id="passwd" onChange={formChange} className={styleInput} />
          </div>
          <button className={styleButton} onClick={submitForm}>Módosítás</button>
        </div>
        <div className="lg:w-1/2 lg:pl-9 pt-9 lg:pt-0">
          <img src={ProfilePic} alt={form.name} className="shadow-lg" />
        </div>
      </div>
      <Toaster />
    </motion.div>
  )
}

export default Profile