import { useState } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import './styles/app.sass'
import Auth from './context/Auth'

import Frontend from './layouts/Frontend'
import Backend from './layouts/Backend'

import Login from './pages/Login'
import Registration from './pages/Registration'

import Home from './pages/Home'
import Score from './pages/Score'
import Favourites from './pages/Favourites'
import Comments from './pages/Comments'
import Friends from './pages/Friends'
import Profile from './pages/Profile'
import Error404 from './pages/Error404'

const App = () => {
  const [guest, setGuest] = useState(true)
  const [user, setUser] = useState({
    id: 0,
    name: '',
    email: '',
    score: 0,
    followers: 0
  })

  return (
    <BrowserRouter>
      <Auth.Provider value={{ setGuest, user, setUser }}>
      {guest ?
        <Frontend>
          <Switch>
            <Route exact path="/regisztracio" component={Registration} />
            <Route path="/" component={Login} />
          </Switch>
        </Frontend>
      :
        <Backend>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/pontgyujtes" component={Score} />
            <Route exact path="/kedvenceim" component={Favourites} />
            <Route exact path="/hozzaszolasaim" component={Comments} />
            <Route exact path="/ismeroseim" component={Friends} />
            <Route exact path="/profilom" component={Profile} />
            <Route path="/" component={Error404} />
          </Switch>
        </Backend>
      }
      </Auth.Provider>
      <footer className="w-full bg-gray-800 text-white text-center text-sm p-3">
        &copy; Nemzeti Művelődési Intézet &nbsp;&ndash;&nbsp; Adatkezelési tájékoztató &nbsp;&ndash;&nbsp; Általános Szerződési Feltételek
      </footer>
    </BrowserRouter>
  )
}

export default App