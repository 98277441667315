import { useContext, useState } from 'react'
import { motion } from 'framer-motion'
import toast, { Toaster } from 'react-hot-toast'

import Auth from '../context/Auth'
import { styleInput, styleButton } from '../styles/classes'

const Login = () => {
  const auth = useContext(Auth)
  const [form, setForm] = useState({
    email: '',
    passwd: ''
  })

  const formChange = event => {
    const fields = { ...form }
    fields[event.target.name] = event.target.value.trim()
    setForm(fields)
  }

  const formSubmit = () => {
    auth.setUser({
      id: 1,
      name: 'Szabó Gergő',
      email: 'szabo.gergo@smapp.hu',
      score: 2682,
      followers: 148,
      country: 1,
      county: 9
    })
    auth.setGuest(guest => guest = false)
  }

  const newPassword = () => {
    toast('Fejlesztés alatt...', { icon: '🛠️' })
  }

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.3 }}>
      <div className="mb-4">
        <label htmlFor="email" className="leading-8 text-base">Email</label>
        <input type="email" name="email" id="email" onChange={formChange} className={styleInput} autoFocus />
      </div>
      <div className="mb-6">
        <label htmlFor="passwd" className="leading-8 text-base">Jelszó</label>
        <input type="password" name="passwd" id="passwd" onChange={formChange} className={styleInput} />
      </div>
      <div className="flex justify-between">
        <button className={styleButton} onClick={formSubmit}>Belépés</button>
        <button className="text-green hover:text-gray-800" onClick={newPassword}>új jelszó kérése</button>
      </div>
      <Toaster />
    </motion.div>
  )
}

export default Login