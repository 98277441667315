import { useContext } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { motion } from 'framer-motion'

import Auth from '../context/Auth'

import ProfilePic from '../images/profile.jpg'

const Backend = ({ children }) => {
  const auth = useContext(Auth)
  const history = useHistory()

  const logout = () => {
    auth.setUser({
      id: 0,
      name: '',
      email: '',
      score: 0,
      followers: 0,
      country: 0,
      county: 0
    })
    history.push('/')
    auth.setGuest(true)
  }

  return (
    <main className="flex h-screen">
      <section className="w-72 bg-white shadow-md">
        <div className="px-12 pt-10 pb-6 border-b-2 border-gray-200">
          <div className="flex justify-center flex-wrap">
            <motion.div
              className="w-28 mt-10 border-2 border-red rounded-full overflow-hidden absolute"
              initial={{ opacity: 0, top: -400 }} animate={{ opacity: 1, top: 0 }}
            >
              <div className="border-4 border-white rounded-full overflow-hidden">
                <img src={ProfilePic} alt="" />
              </div>
            </motion.div>
            <motion.div
              className="mt-4 mb-6 font-semibold text-2xl text-gray-dark mt-32"
              initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2 }}
            >
              {auth.user.name}
            </motion.div>
            <motion.div
              className="flex text-center space-x-8"
              initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.3 }}
            >
              <div>
                <span className="block font-semibold">Pontjaim</span>
                <span className="font-light text-xl">{auth.user.score}</span>
              </div>
              <div>
                <span className="block font-semibold">Követők</span>
                <span className="font-light text-xl">{auth.user.followers}</span>
              </div>
            </motion.div>
          </div>
        </div>
        <motion.nav
          className="pt-2 pb-2 border-b-2 border-gray-200"
          initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5 }}
        >
          <Link to="/" className="block px-12 py-2 hover:text-red hover:bg-gray-100 transition duration-800">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="inline w-6 mr-3 -mt-1 text-gray-600"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" /></svg>
            Hírfolyam
          </Link>
          <Link to="/pontgyujtes" className="block px-12 py-2 hover:text-red hover:bg-gray-100 transition duration-800">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="inline w-6 mr-3 -mt-1 text-gray-600"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" /></svg>
            Pontgyűjtés
          </Link>
          <Link to="/kedvenceim" className="block px-12 py-2 hover:text-red hover:bg-gray-100 transition duration-800">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="inline w-6 mr-3 -mt-1 text-gray-600"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" /></svg>
            Kedvenceim
          </Link>
          <Link to="/hozzaszolasaim" className="block px-12 py-2 hover:text-red hover:bg-gray-100 transition duration-800">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="inline w-6 mr-3 -mt-1 text-gray-600"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" /></svg>
            Hozzászólásaim
          </Link>
          <Link to="/ismeroseim" className="block px-12 py-2 hover:text-red hover:bg-gray-100 transition duration-800">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="inline w-6 mr-3 -mt-1 text-gray-600"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" /></svg>
            Ismerőseim
          </Link>
          <Link to="/profilom" className="block px-12 py-2 hover:text-red hover:bg-gray-100 transition duration-800">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="inline w-6 mr-3 -mt-1 text-gray-600"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
            Profilom
          </Link>
        </motion.nav>
        <motion.div
          className="py-2"
          initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.6 }}
        >
          <div className="block px-12 py-2 hover:text-red hover:bg-gray-100 cursor-pointer transition duration-800" onClick={logout}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="inline w-6 mr-3 -mt-1 text-gray-600"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" /></svg>
            Kijelentkezés
          </div>
        </motion.div>
      </section>
      <section className="mx-auto w-full 2xl:max-w-screen-xl px-12 py-10">
        {children}
      </section>
    </main>
  )
}

export default Backend